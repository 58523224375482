.modal {
    background: rgb(26,33,58,.84);
    text-align: center;
    position: fixed;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    z-index: 1;
    padding-bottom: 10px;
    margin-top: 100px;
    margin-right: 0;
    border-radius: 5px;
}

.editForm {
    line-height: 2em;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background: rgba(26,32,54,.47);;
    color: white;
    width: 100%;
    border-radius: 5px;
}

.newDescription {
    width: 80%; 
    height: 50px;
    resize: none;
    border-radius: 3px;
    margin: 0 auto;
}

.editForm input {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    line-height: 2em;
}

.editForm label {
    text-align: left;
    margin-left: 9%;
}

.modal button {
    margin: 10px;
    border: 1px solid black;
    border-radius: 5px;
}

@media only screen and (min-width: 500px) {
    .modal {
        width: 75%;
    }

    .newDescription {
        height: 100px;
    }
}

@media only screen and (min-width: 700px) {
    .modal {
        max-width: 600px;
        top: 35%;
    }

    .editForm input { 
        margin-top: 10px; 
        margin-bottom: 10px;
        height: 20px;
        padding: 10px;
        border: 1px solid black;
        border-radius: 5px;
    }
}