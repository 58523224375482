.ride-info {
  font-size: 12px;
  border: 1px solid black;
  top: 0px;
  margin-top: 0;
  padding: 5px 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background: rgb(77, 84, 95, .65);
  border: white 1px solid;
  border-radius: 5px 5px 0 0;
  color: white;
}

.labels {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.locations {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
