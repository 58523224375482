.google-map {
    height: 400px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
}

@media only screen and (min-width: 700px) {
    .google-map {
        width: 85%;
    }
}