.searchbar {
  background:rgba(26,32,54,.47);
  border: 1px solid white;
  border-radius: 5px;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 21px;
  color: white;
}
.searchbar input{
  border-radius: 5px;
  min-height: 30px;
  margin-bottom: 7px;
  border:solid 1px #b8e3ea;
  padding-left:5px;
  padding-right:5px;
  margin-right:2px;
}

@media only screen and (max-width: 600px) {
  .searchbar {
    max-width: 554px;
  }
}