.newRideForm {
  line-height: 2em;
  display: block;
  width: 90%;
  margin: 0 auto;
  background: rgba(26,32,54,.47);
  /* filter: blur(5px); */
  /* background:inherit; */
  color: white;
  padding: 15px 0;
  border-radius: 5px;
  border:1px solid white;
}

.newRideForm input{
  width: 95%;
  border: solid 1px #b8e3ea ;
  border-left: 3px solid;
  border-radius: 5px;
  transition: border-color .5s ease-out;
  min-height: 30px;
  padding-left: 5px;
}

.newRideForm input:required:valid {
  border-left-color: green;
}


.newRideForm input:required:invalid{
  border-left-color: Red;
}

.newRideForm div select{
  float: left;
  margin-left: 11px;
  border-radius: 3px;
  min-height: 30px;
  min-width: 95%;
  font-size: 20px;
  font-weight: bold;
}

.newRideForm div{
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.newRideForm div label{
  float: left;
  font-size:20px;
}

.newRideForm button{
  border:1px solid #b8e3ea;
  background: #b8e3ea;
  color:black;
  min-height:31px;
  transition: 1.3s;
  margin-top:5px;
}

.description { 
  width:95%; 
  height: 100px; 
  resize: none;
  border-radius:3px;
  border:#b8e3ea solid 1px;
  border-left:3px solid;
  padding-left: 5px;
}

.newRideForm .description:required:valid{
  border-left-color:green;
  content: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/939465/tick.jpg);
}

.newRideForm .description:required:invalid{
  border-left-color:red;
}





.newRideForm button:hover{
  border: 1px solid #F2DE6A;
  background: #F2DE6A;
  color: black;
  min-height: 35px;
  cursor: pointer;
}

.rideLabel {
  margin: 0 10px;
}

.rideSpace {
  background: white;
}

.createRide {
  margin: 0 auto;
  background: white;
  border-radius: 3px;
  text-align: center;
}


@media only screen and (min-width: 700px) {
  .newRideForm {
    max-width: 470px;
  }
}