.App {
  text-align: center;
  font-family: 'Montserrat Alternates', sans-serif;
  min-height: 100vh;
 
  background-image:linear-gradient(to bottom, rgba(246, 243, 243, 0.52),rgba(47, 57, 116, 0.73)), url('https://images.pexels.com/photos/315938/pexels-photo-315938.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  background-size: cover;
  color:white;
  background-position: center center; 
  background-repeat: no-repeat; 
  background-attachment: fixed; 
}

footer {
  height: 21.440px;
}

main {
  padding-right: 0;
  background: inherit;
}

.frosted-glass {
  background: inherit;
  z-index: 1;
  overflow: hidden;
}

.frosted-glass::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: inherit;
  box-shadow: inset 0 0 3000px rgba(0, 0, 0, .75);
  filter: blur(5px);
  overflow: hidden;
  margin: -10px;
}
.fade-appear{
  opacity: 0.01;
  
} 
 .fade-appear.fade-appear-active{
  opacity:1;
  transition: opacity 3s  ease-in;  
}
.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 6s ease-in;
}

.notfound{
  padding:15px;
  margin: 0;
}
