.ridesList {
  padding-left: 0px;
}

.single-ride {
  margin: 0 auto;
  list-style-type: none;
  margin-bottom: 15px;
  padding-bottom: 20px;
  border-radius: 10px 10px 5px 5px;
  width: 90%;
  background: rgba(26,32,54,.65);
  transition: .75s;
}

.single-ride a:link, .single-ride a:visited, .single-ride a:hover, .single-ride a:active {
  color: white;
  text-decoration: none;
}

.single-ride:hover{

  background:rgba(26,32,54,.99);
}

@media only screen and (min-width: 500px) {
  .single-ride {
    margin: 10px 10px;
    max-width: 50%;
  }

  .ridesList {
    display: flex;
    flex-flow: row wrap;
    align-content: space-around;
    justify-content: center;
  }
}

@media only screen and (min-width: 600px) {
  .single-ride {
    width: 40%;
  }
}

@media only screen and (min-width: 1000px) {
  .single-ride {
    max-width: 390px;
  }
}