.landing {
  color:white;
  font-size: 12px;
}

h1 {
  padding-top:50px;
  margin:0;
}

.intro {
  width:90%;
  font-size:24px;
  margin:0 auto;
}

.title {
  margin-top: 0;
}

.psuedo-footer {
  height: 30px;
}

.logincreds{
  font-size:20px;
  text-align: center;
  margin: 0 auto;
  width:90%;
  margin-bottom: 15px;
  background: rgba(26,32,54,.65);
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px 10px;
}

.loginPageHeader{
  font-size: 26px;
}

.login{
  font-size:26px;
}

@media only screen and (min-width:700px){
  .landing h2{
    padding-top: 20px;
   }

  .landing h2{
    padding-top:50px;
  }

  .intro{
    width: 75%;
  }

  .psuedo-footer {
    height: 60px;
  }

  .logincreds{
    text-align: left;
    width: 60%;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 1000px) {
  .logincreds {
    width: 45%;
  }
}

@media only screen and (min-width:1200px){
  .intro{
    width:40%
  }

  .psuedo-footer {
    height: 100px;
  }

  .logincreds {
    max-width: 483px;
  }
}