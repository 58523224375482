.search-dest {
  padding-right: 5px;
}

.search-start {
  padding-right: 33px;
}

.search-form {
  line-height: 2em;
}

.search-form button{
  border: 1px solid #b8e3ea;
  border-radius:3px;
  background: #b8e3ea;
  color: black;
  min-height: 31px;
  transition: 1.3s;
  margin:5px;
  min-width: 114px;
  margin-left:20px;
}

.search-form button:hover{
  border: 1px solid #F2DE6A;
  background: #F2DE6A;
  color: black;
  min-height: 31px;
  cursor: pointer;
}

.error {
  font-size: 18px;
  padding: 5px 7px;
  width: 90%;
  margin: 0 auto;
  border-radius: 5px;
  color: #FC5C66;
  border: 1px solid #FC5C66;
  margin-bottom: 10px;
  background: rgb(0, 0, 0, .63);
}

.errButton {
  color: #FC5C66;
  border: 1px solid #FC5C66;
  border-radius: 5px;
  margin-left: 10px;
  vertical-align: middle;
}

.searchbar {
  background: #22554e;
  border: 1px solid #22554e;
  border-radius: 5px;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 21px;
  color: white;
}

@media only screen and (min-width: 700px) {
  .searchbar {
    max-width: 500px;
  }
}